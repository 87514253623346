@import 'src/styles/variables';
@import 'src/styles/mixins';

.sectionContainer {
    background: linear-gradient(
        180deg,
        rgba(181, 210, 254, 1) 0%,
        rgba(233, 250, 252, 1) 37%,
        rgba(254, 255, 255, 1) 100%
    );
}

.dataApiSection {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dataApiBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dataApiHeader,
.dataApiContent {
    text-align: center;
}

.dataApiHeader {
    font-size: 50px;
    font-weight: 700;
}

.dataApiContent {
    margin-top: 30px;
    font-size: 30px;
    font-weight: 500;
}

.dataApiPlansBtn {
    width: 365px;
    height: 44px;
    margin-top: 25px;
    border-radius: 4px;
    border: none;
    color: white;
    background: #0fdab5;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}

.daoParticipants {
    margin-top: 85px;
}

.technology,
.daoParticipants {
    display: flex;
    align-items: center;
    justify-content: center;
}

.daoParticipantsIconContainer {
    margin-right: 20px;
}

.technologyTextContainer,
.daoParticipantsTextContainer {
    max-width: 480px;
}

.technologyHeader,
.daoParticipantsHeader {
    font-size: 28px;
}

.technologyText,
.daoParticipantsText {
    margin-top: 10px;
    font-size: 16px;
}

.technology {
    margin-top: 60px;
}

.dataApiHeader,
.dataApiContent,
.daoParticipantsHeader {
    font-family: 'Koho', sans-serif !important;
}

.formContainer {
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 102, 246, 0.2);
    width: 700px;
    border-radius: 25px;
    padding: 50px 150px;
    margin-top: 100px;
    margin-bottom: 100px;

    @media (max-width: 767.98px) {
        width: 500px;
    }

    @media (max-width: 575.98px) {
        width: 350px;

    }
}
.formBlock {
    margin-top: 40px;
    &.priceBlock {
        font-size: 18px;
        font-weight: bold;
    }
}

.formBlock:first-child {
    margin-top: 14px;
}

.totalBlock {
    display: flex;
    justify-content: space-between;
}

.divider {
    width: 100%;
    background-color: #9cb4c2;
    margin-top: 40px;
    height: 1px;
}

.paypalButton {
    width: 100%;
    border: none;
    padding: 12px 20px;
    background: #337bff;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
}
