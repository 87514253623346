@import "src/styles/variables";
@import "src/styles/mixins";

.sectionContent {
    position: relative;
    z-index: 1;
    min-height: 500px;
    flex-direction: column;
    align-items: center;
    display: flex;

    & > h1,
    h2,
    h3,
    h4 {
        font-family: "Koho", sans-serif !important;
    }
}

.sectionContentHeader {
    text-align: center;
    font-size: 38px;
    font-weight: 700;
}

.card {
    display: flex;
    flex-direction: column;
    max-width: 330px;
    min-width: 330px;
    border-radius: 15px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 0;
    background-color: #081348;
    color: white;
    font-size: 16px;
    padding-bottom: 12px;

    & > div {
        border-bottom-right-radius: 30px;
    }

    @media (max-width: 767.98px) {
        max-width: 500px;
        min-width: 500px;
    }

    @media (max-width: 575.98px) {
        max-width: 350px;
        min-width: 350px;
    }
}

.documentationLink {
    display: flex;
    height: 23px;
    max-width: 330px;
    min-width: 330px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #081348;
    justify-content: center;

    @media (max-width: 767.98px) {
        // font-size: 18px;
        margin: auto;
    }
}

.monthlyPrice {
    background-color: #cafff9;
    color: #081348;
    font-size: 16px;
    flex: 1;

    &.whitelist {
        background-color: transparent;
    }
    &.custom {
        background-color: transparent;
        .mainCardContent {
            background-color: $dodger-blue-darker;
            color: white;
        }
    }
}

.mainCardContent {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #9af4de;
    color: #081348;
    padding: 12px 24px 19px 15px;
    flex: 1;

    &.blue {
        background-color: #337bff;
        color: #ffffff;
    }
}

.monthlyPriceContainer {
    margin: 14px 0 0 19px;
}

.yearlyPriceContainer {
    margin: 10px 0 0 19px;
}

.price {
    font-weight: 800;
}

.contentList {
    padding-left: 20px;
    margin-top: 20px;
    font-size: 14px;

    li {
        margin-bottom: 10px;
    }

    .boldText {
        font-weight: 700;
    }
}

.cardsRow {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 15px;
    width: 700px;
    gap: 30px;

    &:first {
        margin-top: 35px;
    }
}

.linkRow {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 28px;
    width: 700px;
    gap: 30px;
    &:first {
        margin-top: 35px;
    }

    @media (max-width: 767.98px) {
        flex-direction: column;
    }
}

.documentLink {
    color: #081348;
    margin-left: 5px;
}

.wavyBg {
    position: absolute;
    width: 100%;
    top: 230px;
    left: 0;
    z-index: -1;
}

.proCardTitle::after {
    content: "COMING SOON";
    width: 99px;
    height: 21px;
    margin-left: 16px;
    background: #ffffff;
    border-radius: 12px 0px;

    font-weight: 700;
    font-size: 10px;
    color: #081348;

    padding: 3px 13px 3px 11px;
}

