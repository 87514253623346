@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    margin-left: 20px;

    .button {
        @include font-style($size: 16px, $bold: 500, $color: $fiord, $transform: none);
        width: 90px;
        height: 40px;
        border: 2px solid $nepal;
        transition: 0.3s border-color;

        &:hover {
            background-color: transparent;
            border-color: $fiord;
        }
    }

    .buttonActive {
        border: 2px solid $fiord;
    }

    @media (max-width: 991.98px) {
        margin-left: 14px;
    }

    @media (max-width: 575.98px) {
        margin-left: 10px;

        .button {
            font-size: 12px;
            height: 30px;
            width: 60px;
        }
    }
}
