@import '../../../../../../../../../../styles/variables';
@import '../../../../../../../../../../styles/mixins';

.tableHeader {
    @include display-flex($align: center);
    padding: 18px 20px 18px;

    & > div {
        @include table-header-font-styles();
        color: #447CF7;
    }

    .numberOfLoyalVoters {
        width: 33%;
    }

    .numberOfMissingVoters {
        width: 33%;
    }

    .numberOfNonParticipatingTokenHolder {
        width: 34%;
    }
}
