@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;

    .premiumWindow {
        padding: 180px;
        position: relative;
    }

    .content {
        padding: 10px 0 60px;

        .mainInfoContent {
            column-gap: 10px;
            padding: 10px 0 0 0;

            @media (max-width: 991.98px) {
                @include display-flex($align: center, $direction: column);
            }
        }

        .carouselContainer {
            margin: 24px 10px 0 10px;
            background-color: #D7E4F4;
            padding: 16px 16px 10px 16px;
            border-radius: 16px;

            .title {
                @include display-flex($align: center);

                .infoIcon {
                    margin: 0 8px;
                }

                .titleText {
                    @include font-style($size: 18px, $bold: 700, $color: #1F3B82);

                    @media (max-width: 767.98px) {
                        font-size: 16px;
                    }
                }
            }

            .carousel {
                width: 100%;
                padding: 0 15px 0 15px;

                .carouselWrapper {
                    max-width: 1270px;
                    border: none;

                    .notFoundWrapper {
                        width: auto;
                        margin-left: 16px;
                        margin-right: 16px;
                    }

                    & > div {
                        & > div {
                            & > a {
                                margin: 0 4px;
                            }
                        }
                    }

                    @media (max-width: 767.98px) {
                        & > div {
                            & > div {
                                & > a {
                                    &:first-child {
                                        margin-left: 16px;
                                    }

                                    &:last-child {
                                        margin-right: 16px;
                                    }
                                }
                            }
                        }
                    }
                }

                @media (max-width: 767.98px) {
                    padding-left: 0;
                    padding-right: 0;

                    .stakeholdersContainer {
                        height: 114px;
                    }
                }
            }
        }
    }

    .tablesWrapper {
        display: grid;
        grid-template-columns: repeat(2, minmax(300px, 1fr));
        gap: 24px;
        padding: 30px 10px;
        position: relative;

        @media (max-width: 767.98px) {
            grid-template-columns: 1fr;
        }
    }

    .voterGroupsTable {
        margin: 24px 10px 0;
    }

}
