@import "src/styles/variables";
@import "src/styles/mixins";

.sectionContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(180deg, #c5dcff 0%, #e4f6fd 67%, white 100%);
}

.sectionContentHeader {
    text-align: center;
    font-size: 38px;
    font-weight: 700;
    font-family: "Koho", sans-serif !important;
}

.blocksRow {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 550px;
    margin: auto;
    @media (max-width: 767.98px) {
        width: 100%;
    }
}

.block {
    margin-top: 50px;
    display: flex;
    text-align: left;
    width: 100%;

    @media (max-width: 575.98px) {
        flex-direction: column;
    }
}

.textContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logoContainer {
    height: 75px;
    width: 75px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(6, 102, 246, 0.2);
    border-radius: 100px;
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(0deg);
    margin-right: 20px;

    @media (max-width: 575.98px) {
        margin: auto;
    }
}

.blockHeader {
    font-size: 22px;

    @media (max-width: 767.98px) {
        font-size: 18px;
    }

    @media (max-width: 575.98px) {
        font-size: 15px;
        width: 100%;
        align-items: center;
        text-align: center;
        margin: auto;
    }
}

.blockText {
    font-size: 16px;

    @media (max-width: 767.98px) {
        font-size: 15px;
    }

    @media (max-width: 575.98px) {
        font-size: 12px;
        width: 100%;
        align-items: center;
        text-align: center;
        margin: auto;
    }
}
