@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableRow {
    @include display-flex();
    padding: 10px 14px;
    border-top: 1px solid $catskill-white;

    .rowRed {
        background-color: #ffcccc;
    }

    .rowCellWrapper {
        @include display-flex($align: center);
        padding-right: 20px;
    }

    .rowCellTextOverflow {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .rowCellText {
        @include font-style($size: 14px, $bold: 400, $color: $fiord);
        @extend .rowCellTextOverflow;
    }

    .redText {
        @include font-style($size: 14px, $bold: 400, $color: red);
        @extend .rowCellTextOverflow;
    }

    .greenText {
        @include font-style($size: 14px, $bold: 400, $color: green);
        @extend .rowCellTextOverflow;
    }


    .rowCellBoldText {
        font-weight: 700;
    }

    .linkWrapper {
        color: $dodger-blue-darker;

        & > span {
            color: $dodger-blue-darker;
            @extend .rowCellBoldText;
        }
    }
}
