@import 'src/styles/variables';
@import 'src/styles/mixins';

.content {
    background-color: #D7E4F4;
    padding: 16px;
    border-radius: 16px;

    .headSection {
        @include display-flex($align: center, $justify: space-between);

        .textSection {
            @include display-flex($align: center);

            .infoIcon {
                margin: 0 8px;
            }

            & > h4 {
                @include font-style($size: 18px, $bold: 700, $color: #1F3B82);
            }

            & > h5 {
                @include font-style($size: 12px, $bold: 450, $color: $fiord);
            }
        }
    }

    .tableWrapper {
        margin-top: 20px;

        .tableContainer {
            padding: 0 10px 0 0;
            background-color: $white;
            border-radius: 6px;
            border: 1px solid #B0C2CD;

            .tableHeader {
                @include display-flex();
                padding: 12px 16px;

                & > div {
                    @include display-flex($align: center);

                    & > div {
                        @include display-flex($align: center);

                        & > span {
                            @include table-header-font-styles();
                            color: #447CF7;
                        }
                    }
                }
            }

            .rowsContainer {
                height: 230px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 3px;
                    background-color: $catskill-white;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $nepal;
                }
            }
        }
    }

    .loaderBox {
        position: static;
        margin-top: 40px;
    }
}
