@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
    width: 100%;
    max-width: 940px;
    margin: 0 auto;

    .container {
        padding: 60px 20px;

        .link {
            @include display-flex($align: center);
            color: $fiord;
            transition: color 0.3s;

            .arrowIcon {
                @include font-style($size: 14px);
            }

            .text {
                @include font-style($size: 14px, $bold: 400);
                margin-left: 4px;
            }

            &:hover {
                color: $dodger-blue-darker;
            }
        }

        .mainContent {
            @include display-flex($align: center, $direction: column);

            .title {
                @include font-style($size: 40px, $bold: 500, $color: $fiord, $lineHeight: 44px, $family: $font-koho);
            }

            .card {
                width: 100%;
                max-width: 450px;
                background: $white;
                box-shadow: 0px 0px 10px rgba(51, 123, 255, 0.3);
                border-radius: 12px;
                padding: 45px;
                margin-top: 40px;

                .headingText {
                    @include font-style($size: 20px, $bold: 700, $color: $fiord);
                }

                .creditCardInfo {
                    @include font-style($size: 16px, $bold: 400, $color: $fiord);
                    margin-top: 20px;
                }

                .changeCard {
                    @include display-flex($align: flex-start);
                    margin-top: 20px;

                    & > div {
                        @include font-style($size: 14px, $bold: 400, $color: $dodger-blue-darker);
                        position: relative;
                        cursor: pointer;

                        &::before {
                            content: '';
                            width: 100%;
                            height: 1px;
                            position: absolute;
                            bottom: -1px;
                            background-color: $dodger-blue-darker;
                        }
                    }
                }

                .submitButtonWrapper {
                    @include display-flex($align: center, $justify: center, $direction: column);
                    margin-top: 40px;

                    .submitButton {
                        @include font-style($size: 18px, $bold: 700, $color: $white, $transform: none);
                        width: 162px;
                        height: 44px;
                        background-color: $bright-turquoise;

                        &:disabled {
                            background-color: $ghostwhite;
                            color: $heather;
                        }

                        .loader {
                            position: static;
                        }
                    }

                    .errorText {
                        @include font-style($size: 10px, $color: $lavender-magenta, $bold: 500);
                        margin-top: 4px;
                    }
                }
            }
        }
    }
}
