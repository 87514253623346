@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
    @include display-flex($align: center, $justify: center, $direction: column);

    .head {
        width: 100%;
        max-width: 950px;
        
        .navigationBackBlock {
            @include display-flex($align: flex-end);
            width: 100%;
            margin-bottom: 10px;

            .accountSettingsLink {
                @include display-flex($align: center);
                color: $fiord;
                transition: 0.3s color;

                .arrowIcon {
                    @include font-style($size: 14px);
                }

                .text {
                    @include font-style($size: 14px, $bold: 400);
                }

                &:hover {
                    color: $dodger-blue-darker;
                }
            }
        }
    }

    .title {
        @include font-style($size: 40px, $bold: 500, $color: $fiord, $family: $font-koho !important);
    }

    .description {
        @include font-style($size: 16px, $bold: 400, $color: $fiord);
        margin-top: 12px;
    }

    .cardsWrapper {
        @include display-flex($align: flex-start);
        margin-top: 70px;

        .card {
            width: 450px;
            background-color: $white;
            box-shadow: 0px 0px 20px rgba(51, 123, 255, 0.2);
            border-radius: 12px;
            padding: 45px;

            &:last-child {
                margin-left: 50px;
            }

            .headText {
                @include font-style($size: 20px, $bold: 700, $lineHeight: 24px, $color: $fiord);
            }

            .descriptionText {
                @include font-style($size: 14px, $bold: 400, $lineHeight: 20px, $color: $fiord);
                margin-top: 6px;
            }

            &Header {
                @include display-flex($align: center, $justify: space-between);

                .editOption {
                    @include font-style($size: 14px, $bold: 400, $color: $dodger-blue-darker);
                    position: relative;
                    cursor: pointer;
                    margin-left: 12px;

                    &::before {
                        content: '';
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        bottom: 0;
                        background-color: $dodger-blue-darker;
                    }
                }

                .planName {
                    @include font-style(
                        $size: 32px,
                        $bold: 600,
                        $lineHeight: 30px,
                        $color: $fiord-darker,
                        $family: $font-koho
                    );
                }
            }

            .sumInfo {
                @include font-style($size: 20px, $bold: 400, $color: $fiord);
                margin-top: 10px;
            }

            .totalSumInfo {
                @include display-flex($align: center, $justify: space-between);
                margin-top: 70px;
                padding-top: 25px;
                border-top: 2px solid $fiord;

                & > h5 {
                    @include font-style($size: 20px, $bold: 700, $color: $fiord);
                }
            }

            .startPlanButton {
                @include font-style($size: 18px, $bold: 700, $color: $white, $transform: lowercase);
                width: 100%;
                height: 44px;
                background-color: $bright-turquoise;
                margin-top: 40px;

                &:disabled {
                    background-color: $ghostwhite;
                    color: $heather;
                }

                .loader {
                    position: static;
                }
            }

            .errorText {
                @include font-style($size: 10px, $color: $lavender-magenta, $bold: 500);
                margin-top: 4px;
            }

            .userDetails {
                margin-top: 16px;

                & > p {
                    @include font-style($size: 14px, $bold: 700, $lineHeight: 20px, $color: $fiord);
                }

                .locationInfo {
                    @include display-flex($wrap: wrap);
                    @include font-style($size: 14px, $bold: 400, $lineHeight: 20px, $color: $fiord);
                    margin-top: 6px;

                    & > span {
                        margin-left: 4px;

                        &:first-child {
                            margin-left: 0;
                        }
                    }

                    width: 210px;
                }
            }

            .block {
                margin-top: 60px;

                .header {
                    @include display-flex($align: flex-end, $justify: space-between);

                    .paymentSecureImage {
                        width: 33px;
                        height: 42px;
                        margin-left: 10px;
                        margin-bottom: 3px;
                    }
                }

                .radioButtonsContent {
                    @include display-flex($justify: space-between);
                    margin-top: 20px;
                    margin-bottom: 60px;

                    .radioWrapper {
                        .periodRadioInputsWrapper {
                            flex-direction: column;

                            & > div {
                                &:last-child {
                                    margin-top: 20px;
                                }

                                & > label {
                                    font-size: 16px;
                                }
                            }
                        }
                    }

                    .pricingsBlock {
                        .price {
                            @include font-style($size: 16px, $bold: 400, $lineHeight: 24px, $color: $fiord);

                            &:last-child {
                                margin-top: 20px;
                            }
                        }

                        .bold {
                            font-weight: 700;
                        }
                    }
                }
            }

            .removeMarginTop {
                margin-top: 0;
            }

            .paymentRadioButtons {
                margin-top: 20px;

                .paymentRadioInputsWrapper {
                    flex-direction: column;

                    & > div:last-child {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}
