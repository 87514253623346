@import 'src/styles/variables';
@import 'src/styles/mixins';

.block {
    .header {
        @include display-flex($align: flex-end, $justify: space-between);

        .textInfo {
            .headText {
                @include font-style($size: 20px, $bold: 700, $lineHeight: 24px, $color: $fiord);
            }

            .descriptionText {
                @include font-style($size: 14px, $bold: 400, $lineHeight: 20px, $color: $fiord);
                margin-top: 6px;
            }
        }

        .paymentSecureImage {
            width: 33px;
            height: 42px;
            margin-left: 10px;
            margin-bottom: 3px;
        }
    }
}

.creditCardBlock {
    display: none;
    margin-top: 30px;

    .header {
        @include display-flex($align: center, $justify: space-between);

        & > p {
            @include font-style($size: 16px, $bold: 400, $lineHeight: 20px, $color: $fiord);
        }

        .cardImagesWrapper {
            & > img {
                margin: -20px -16px;
            }
        }
    }

    .creditCardFields {
        .fieldWrapper {
            margin-top: 20px;
        }

        .bluesnapFieldWrapper {
            position: relative;

            .errorText {
                @include font-style($size: 10px, $color: $lavender-magenta, $bold: 500);
                margin-top: 4px;
                position: absolute;
                right: 0;
            }
        }

        .fieldLabel {
            display: none;
        }

        .bluesnapField {
            width: 100%;
            height: 30px;
            border: none;
            border-bottom: 1px solid $nepal;
        }

        .groupFieldsWrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 60px;
            margin-top: 20px;
        }
    }
}

.creditCardBlockVisible {
    display: block;
}
