@import "src/styles/variables";
@import "src/styles/mixins";

.sectionContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sectionContentHeader {
    margin-bottom: 25px;
    text-align: center;
}

.blocksRow {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    @media (max-width: 575.98px) {
        flex-direction: column;
    }
}

.block {
    max-width: 325px;
    min-width: 325px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &:first-child {
        margin-right: 30px;
    }

    @media (max-width: 767.98px) {
        max-width: 280px;
        min-width: 280px;
    }

    @media (max-width: 575.98px) {
        max-width: 100%;
        min-width: 100%;
    }
}

.logoContainer {
    height: 75px;
    width: 75px;
    background: linear-gradient(127.03deg, #347bff33 2.49%, #3d8dd833 28.11%, #1ac1e633 59.33%, #0fdab533 82.55%);
    box-shadow: 0px 0px 20px rgba(6, 102, 246, 0.2);
    border-radius: 100px;
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(0deg);

    & img {
        margin-left: 4px;
    }
}

.blockHeader {
    margin-top: 15px;
    font-size: 16px;
}

.sectionContentHeader {
    font-family: "Koho", sans-serif !important;
    font-size: 38px;
    font-weight: 700;
}
