@import 'src/styles/variables';
@import 'src/styles/mixins';

.sectionContent {
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
        0deg,
        rgba(197, 219, 255, 1) 0%,
        rgba(228, 246, 253, 1) 67%,
        rgba(255, 255, 255, 1) 100%
    );
}

.formContainer {
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 102, 246, 0.2);
    width: 700px;
    border-radius: 25px;
    padding: 50px 150px;
    z-index: 5;

    .header {
        margin-bottom: 5px;
    }

    .description {
        margin-bottom: 30px;
    }

    .billingForm {
        .radioButtonsWrapper {
            margin-top: 30px;

            & > div {
                & > div:last-child {
                    margin-left: 40px;
                }
            }
        }

        .select {
            padding: 5px 10px;
            border: 1px solid $nepal;
            border-radius: 20px;
            width: 250px;
            margin-top: 30px;

            > div {
                width: auto;
                border: 0;
                padding: 0 10px;
            }
        }
    }

    @media (max-width: 767.98px) {
        max-width: 500px;
        min-width: 500px;
        padding: 50px 100px;
    }

    @media (max-width: 575.98px) {
        max-width: 350px;
        min-width: 350px;
        padding: 50px 50px;
    }
}

.yearDurationPlan {
    margin-left: 50px;

    &::after {
        content: 'SAVE 15%';
        margin-left: 7px;
        font-weight: 900;
        font-size: 9px;
        color: #0fdab5;
        border: 1px solid #0fdab5;
        border-radius: 20px;
        padding: 4px 7px 4px 9px;
    }
}

.accountHolderHeader {
    margin-top: 80px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.details {
    margin-top: 30px;
}

.phoneField {
    display: flex;
}

.countryCodeInputWrapper,
.countryInputWrapper {
    width: 110px;
}

.phoneInputWrapper,
.zipCodeInputWrapper {
    flex: 1;
    margin-left: 30px;
}

.agreements {
    margin-top: 80px;
    display: flex;
}

.agreementsText {
    margin-left: 11px;
    font-size: 14px;
    line-height: 21px;
    color: #3d536c;
}

.documentLink {
    color: #3d536c;
    font-style: initial;
    text-decoration: underline;
}

.continueBtn {
    border: none;
    outline: none;
    width: 414px;
    height: 44px;
    background: #9cb4c2;
    border-radius: 4px;
    margin-top: 40px;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: end;
    text-align: center;
    padding: 0 17px;

    @media (max-width: 767.98px) {
        width: 100%;
    }
}

.activeContinueBtn {
    background: #0fdab5;
}

.btnArrow {
    margin-top: 2px;
}

.continueBtnText {
    flex: 1;
    padding-left: 20px;
}

.error {
    color: red;
    padding-top: 10px;
}

.errorText {
    @include font-style($size: 10px, $color: $lavender-magenta, $bold: 500);
    margin-right: 8px;
    position: absolute;
}
