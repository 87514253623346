.container {
    font-family: "Koho", sans-serif;
    color: #081348;
    font-size: 14px;
    background-color: #feffff;
    flex: 1;
}

.container .section {
    padding: 90px 29px 0;
}

.container .planSection {
    padding: 50px 290px 100px;

    @media (max-width: 575.98px) {
        padding: 50px 0px;
    }
}

.sectionsDivider {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
}

.waves {
    width: 100%;
    margin-top: 90px;
    height: 100px;
    background: url("../../assets/png/waves.png");
    background-size: cover;
}
